<template>
  <div class="box">
    <van-image class="bgimage" :src="require('../assets/image/bg_share.png')" />

    <div class="iphone" :style="obj">
      <van-image
        :src="require('../assets/image/btn_iPhone.png')"
        @click="iphoneClick"
      />
    </div>

    <van-overlay :show="show">
      <div ref="weixinStyle" class="bgTips" @click.stop>
        <div class="block">
          1、请点击右上角选择{{ "\xa0" }}<van-icon name="ellipsis" />{{
            "\xa0"
          }}按钮<br /><br />2、选择浏览器打开
          <div class="rightArrow">↗</div>
        </div>
      </div>
    </van-overlay>

    <div class="android" :style="obj1">
      <van-image
        :src="require('../assets/image/btn_Android.png')"
        @click="androidClick"
      />
    </div>

    <van-overlay :show="show">
      <div ref="weixinStyle" class="bgTips" @click.stop>
        <div class="block">
          1、请点击右上角选择{{ "\xa0" }}<van-icon name="ellipsis" />{{
            "\xa0"
          }}按钮<br /><br />2、选择浏览器打开
          <div class="rightArrow">↗</div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      brands: {
        IPHONE: "IPHONE|IPAD|IPOD|IOS",
        OPPO: "OPPO",
        VIVO: "VIVO",
        HUAWEI: "HUAWEI",
        XIAOMI: "XIAOMI|REDMI",
      },
      hasOwnProp: "",
      userAgent: "",
      os: "",
      brand: "",
      show: false,
      screenWidth: document.body.clientWidth,
      obj: {
        position: "absolute",
        bottom: "210px",
        left: "190px",
        // min-width: '150px',
        // max-width: '300px',
        width: '40%'
      },
       obj1: {
        position: "absolute",
        bottom: "80px",
        left: "190px",
        // min-width: '150px',
        // max-width: '300px',
        width: '40%'
      },
    };
  },
created () {
  this.screenWidth = this.screenWidth - 1
},
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
      })();
    };
  },
  watch: {
    // console.log(window.screen);
    screenWidth(val) {
      this.screenWidth = val;
      let widths = this.screenWidth;
      // console.log(widths);
      if (widths <= 470 && widths >= 350) {
        this.obj.bottom = '130px'
        this.obj.left=  '125px'
        this.obj1.bottom = '50px'
        this.obj1.left=  '125px'
      }else if (widths <= 350 ) {
        this.obj.bottom = '110px'
        this.obj.left=  '100px'
        this.obj1.bottom = '50px'
        this.obj1.left=  '100px'
      } else {
        this.obj.bottom = '210px'
        this.obj.left=  '190px'
        this.obj1.bottom = '80px'
        this.obj1.left=  '190px'
      }
    },
  },
  methods: {
    iphoneClick() {
      // console.log("你好");
      let weixin = this.$refs["weixinStyle"];

      if (this.isWeixin()) {
        this.show = true;
        weixin.style.display = "block";
      } else {
        window.location.href =
          "https://itunes.apple.com/cn/app/id1287004897?mt=8";
      }

      // console.log(this.isWeixin());
      // window.location.href="http://apps.apple.com/cn/app/id387682726"
    },
    //这个函数用来判断当前浏览器是否微信内置浏览器，是微信返回true，不是微信返回false
    isWeixin() {
      let ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
        // this.isAndroid();
      } else {
        return false;
      }
    },
    androidClick() {
      // console.log("你好");
      let device_type = navigator.userAgent; //获取userAgent信息
      // let ua_pub = window.navigator.userAgent;
      // let u = navigator.userAgent;
      // let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
      // let ua = window.navigator.userAgent.toLowerCase();
      // let isWeix = ua.indexOf("micromessenger") !== -1;
      // let href = window.location.href;
      // if (this.isWeixin()) {
      //   window.location.href = "https://www.baidu.com";
      //   return true;
      // } else if (ua_pub.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
      //   return;
      //   // window.location.href = 'https://itunes.apple.com/cn/app/%E8%B6%A3%E5%90%AC%E5%B0%8F%E8%AF%B4-%E6%9C%89%E5%A3%B0%E5%B0%8F%E8%AF%B4%E6%87%92%E4%BA%BA%E5%90%AC%E4%B9%A6%E7%A5%9E%E5%99%A8/id1440140641?mt=8'
      // } else {
      //   if (ua.match(/MicroMessenger/i) === "micromessenger" && isAndroid) {
      //     // 跳转应用宝
      //     window.location.href =
      //       "https://a.app.qq.com/o/simple.jsp?pkgname=包名";
      //     $("body,.box").animate({ scrollTop: 0 }, 300);
      //   }
      //   if (href.indexOf("dym") !== -1) {
      //     // alert('dym')
      //     return;
      //   } else {
      // console.log(isAndroid);
      // console.log(device_type);

      let weixin = this.$refs["weixinStyle"];
      if (this.isWeixin()) {
        this.show = true;
        weixin.style.display = "block";
      } else {
        // 华为
        if (device_type.indexOf("HUAWEI") > -1) {
          window.location.href =
            "https://a.app.qq.com/o/simple.jsp?pkgname=com.xcgl.wukong";
          // OPPO
        } else if (
          device_type.indexOf("OPPO") > -1 ||
          device_type.indexOf("PCRM") > -1
        ) {
          window.location.href = "market://details?id=com.xcgl.wukong";
          // VIVo
        } else if (device_type.indexOf("Vivo") > -1) {
          window.location.href =
            "https://h5coml.vivo.com.cn/h5coml/appdetail_h5/browser_v2/index..box?appId=3397265&resource=301&source=7";
          // MI
        } else if (
          device_type.indexOf("MI") > -1 ||
          device_type.indexOf("Mi") > -1
        ) {
          window.location.href = "http://app.xiaomi.com/detail/1459406";
          //其他安卓手机
        } else {
          window.location.href =
            "https://a.app.qq.com/o/simple.jsp?pkgname=com.xcgl.wukong";
        }
      }
    },
  },
};
</script>

<style lang="less">
.box {
  position: relative;
}
// .iphone {
//   // position: absolute;
//   // bottom: 130px;
//   // left: 125px;
//   // min-width: 150px;
//   // max-width: 300px;
//   // width: 40%;
//   // width: 170px;
// }
// .android {
//   position: absolute;
//   bottom: 50px;
//   left: 125px;
//   min-width: 150px;
//   max-width: 300px;
//   width: 40%;
//   // width: 170px;
// }
.bgTips {
  position: relative;
  // display: none;
  display: none;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.block {
  position: absolute;
  left: 50px;
  width: 260px;
  height: 100px;
  margin: 0 auto;
  margin-top: 150px;
  padding: 20px;
  font-size: 18px;
  color: #fff;
  // background-color: #fff;
  word-spacing: 1px;
}
.rightArrow {
  position: absolute;
  top: -60px;
  right: 30px;
  font-size: 90px;
  color: skyblue;
}
</style>